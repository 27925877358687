// @ts-strict
import { Ellipsis, FormField, Section } from 'components'
import { useLeadGroupableContext } from 'contexts'
import { LeadGroupableAsideButtonForm } from './LeadGroupableAsideButtonForm'

export const LeadGroupableAside = () => {
  const { leadGroupable, isLoading } = useLeadGroupableContext()

  if (isLoading || !leadGroupable) {
    return <Section title="Information" />
  }

  return (
    <Section
      title="Information"
      actions={<LeadGroupableAsideButtonForm leadGroupable={leadGroupable!} />}
    >
      <FormField title="Name" inline>
        <Ellipsis>{leadGroupable?.name}</Ellipsis>
      </FormField>
      <FormField title="Active" inline>
        <Ellipsis>{leadGroupable?.active ? 'Yes' : 'No'}</Ellipsis>
      </FormField>
      <FormField title="Priority" inline>
        <Ellipsis>{leadGroupable?.priority}</Ellipsis>
      </FormField>
      <FormField title="Slug" inline>
        <Ellipsis>{leadGroupable?.slug}</Ellipsis>
      </FormField>
    </Section>
  )
}
