import { InvestorLead } from 'models'
import { getHapiRequestParams, InvestorLeadsSchema } from 'schema'
import { getLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

const investorLeadsResponseShape: InvestorLeadsSchema = {
  investorLeads: ['pplReferral', { investor: [] }, { providerLead: [] }, { estimatedRange: [] }]
}

export const fetchInvestorLeads = async (leadId: string) => {
  const searchParams = getHapiRequestParams(investorLeadsResponseShape)
  const url = getLeadsUrl({ id: leadId, action: 'investor-leads', searchParams })
  const response = await getRequest(url)

  return await deserializeAsync(response.data, {
    transform: InvestorLead.create
  })
}
