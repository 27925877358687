import { useState } from 'react'
import { BuyerLead, SellerLead } from 'models'
import { AgentScreenFields, ClientScreenFields, InvestorScreenFields } from './types'

type Lead = BuyerLead | SellerLead

const isInvestorIntro = (introType?: string): boolean => {
  return introType === 'Investor Intro'
}

const isAgentIntro = (introType?: string): boolean => {
  return introType === 'Agent Intro'
}

const isEitherIntro = (introType?: string): boolean => {
  return introType === 'Both'
}

export const agentRequiredFields = (lead: Lead, introType?: string): AgentScreenFields => {
  const baseAgentFields = { previousAgent: true }

  if (isInvestorIntro(introType)) {
    return {}
  }

  return lead.callReview?.previousAgent === 'Yes'
    ? { ...baseAgentFields, previousAgentName: true, previousAgentBrokerage: true }
    : baseAgentFields
}

export const investorRequiredFields = (
  lead: BuyerLead | SellerLead,
  active: boolean,
  introType?: string
): InvestorScreenFields => {
  if (
    lead.userType === 'seller' && active
      ? isAgentIntro(introType)
      : lead.sourcePageType === 'offers'
  ) {
    return {
      askedInvestorQualifyingQuestions: true,
      pitchedInvestorToClient: true
    }
  }

  if (active && (isInvestorIntro(introType) || isEitherIntro(introType))) {
    return {
      yearBuilt: true,
      howLongOwned: true,
      occupiedBy: true,
      lotSize: true,
      garageSize: true,
      ownershipStatus: true,
      condition: true,
      squareFeet: true,
      beddroomsCount: true,
      bathroomsCount: true,
      homeFeatures: false,
      investorIntroQuantity: true
    }
  }
  return {}
}

export const clientRequiredFields = (lead: BuyerLead | SellerLead): ClientScreenFields => {
  const baseClientFields = {
    why: true,
    alreadyHasAgent: true,
    qualificationMethod: true,
    interestedInOtherside: true
  }

  return lead.userType === 'seller'
    ? { ...baseClientFields, clientInterestedIn: true, homeListed: true }
    : { ...baseClientFields, buyerProgress: true, preApproved: true, interestedInLoan: true }
}

export const useAgentRequiredFields = (lead: BuyerLead | SellerLead) => {
  const [agentScreenRequiredFields, setAgentScreenRequiredFields] = useState<AgentScreenFields>(
    agentRequiredFields(lead)
  )

  return { agentScreenRequiredFields, setAgentScreenRequiredFields }
}
