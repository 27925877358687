import { Text } from '@foundation/components'
import {
  Block,
  Contents,
  Duration,
  ExternalLink,
  FormField,
  FormFieldSet,
  InlineEditSelect,
  List,
  MapBox,
  Section,
  Sections,
  ViewMore
} from 'components'
import { LeadEditAgentNoteButtonForm, LeadNewSalesNoteButtonForm } from 'content/Lead'
import { ParamsProvider, useLeadContext } from 'contexts'
import { useQueuedLeadsController } from 'controllers'
import { useFeatureFlags, useFlexUi } from 'hooks'
import { getAgentMatchingLeadField } from 'lookups'
import { isAgentMatchingLead, isBuyerLead, isSellerLead } from 'models'
import { LeadAgents } from 'pages/LeadPage/LeadDetailsTab/LeadAgents'
import {
  d,
  dataOrDash,
  DEFAULT_STRING,
  formatDate,
  formatPhone,
  formatPrice,
  stringifyValue,
  titleize,
  toSearchParam
} from 'utils'
import { AgentMatchingLeadAgentScreenSection } from './AgentMatchingLeadAgentScreenSection'
import { AgentMatchingLeadBBYSScreenSection } from './AgentMatchingLeadBBYSScreenSection'
import { AgentMatchingLeadClientScreenSection } from './AgentMatchingLeadClientScreenSection'
import { AgentMatchingLeadDetailsSection } from './AgentMatchingLeadDetailsSection'
import { AgentMatchingLeadInvestorScreenSection } from './AgentMatchingLeadInvestorScreenSection'
import { AgentMatchingLeadLenderScreenSection } from './AgentMatchingLeadLenderScreenSection'
import { AgentMatchingLeadMortgageScreenSection } from './AgentMatchingLeadMortgageScreenSection'
import { AgentMatchingLeadPropertyDetailsSection } from './AgentMatchingLeadPropertyDetailsSection'
import { ResolvePendingReviewButtonForm } from './ResolvePendingReviewButtonForm'
import * as S from './styles'

export const AgentMatchingLeadDetails = () => {
  const { lead, updateLead } = useLeadContext()
  const { salesAppBuyersSellersPendingActions, salesOpsReferralLeadUpdates } = useFeatureFlags([
    'sales-app-buyers-sellers-pending-actions',
    'sales-ops-referral-lead-updates'
  ])
  const { salesAppInvestorAutoIntroFields } = useFeatureFlags([
    'sales-app-investor-auto-intro-fields'
  ])
  const { isActive, getSinceDate } = useQueuedLeadsController()
  const { isFlexUi } = useFlexUi()

  if (!isAgentMatchingLead(lead)) {
    return null
  }

  const updateLeadField = (value: Record<string, string | number | Record<string, string>>) => {
    updateLead.mutateAsync(value)
  }

  const updateCallReview = (key: string, value: string) => {
    updateLeadField({ callReview: { [key]: value } })
  }

  const editAgentNoteFormButton = <LeadEditAgentNoteButtonForm />
  const newSalesNoteFormButton = <LeadNewSalesNoteButtonForm />

  const needsListingReviewField = getAgentMatchingLeadField('needsListingReview')
  const pendingActionsField = getAgentMatchingLeadField('pendingActions')

  return (
    <Contents>
      {salesOpsReferralLeadUpdates.enabled ? (
        <>
          <AgentMatchingLeadDetailsSection
            lead={lead}
            updateField={updateLeadField}
            updateCallReview={updateCallReview}
          />
          <AgentMatchingLeadPropertyDetailsSection
            lead={lead}
            updateField={updateLeadField}
            updateCallReview={updateCallReview}
          />
          {isBuyerLead(lead) && (
            <Sections>
              {lead.partnerLender && (
                <Section title="Lender Details">
                  <FormField title="Lender Name" inline>
                    {lead.partnerLender.lenderName}
                  </FormField>
                  <FormField title="Lender ID" inline>
                    {lead.partnerLender.externalLenderId}
                  </FormField>
                </Section>
              )}
              {lead.partnerLoanOfficer && (
                <Section title="Loan Officer Details">
                  <FormField title="Loan Officer" inline>
                    {lead.partnerLoanOfficer.loanOfficerName}
                  </FormField>
                  <FormField title="Loan Officer Phone" inline>
                    {formatPhone(lead.partnerLoanOfficer.loanOfficerPhone)}
                  </FormField>
                  <FormField title="Loan Officer ID" inline>
                    {lead.partnerLoanOfficer.loanOfficerId}
                  </FormField>
                </Section>
              )}
            </Sections>
          )}
          <Sections>
            <Section title="Sales Notes" actions={newSalesNoteFormButton}>
              <ViewMore height={120}>{lead.salesNotes}</ViewMore>
            </Section>
            <Section title="Notes to Agents" actions={editAgentNoteFormButton}>
              <ViewMore height={120}>{lead.notesToAgents}</ViewMore>
            </Section>
          </Sections>
          <AgentMatchingLeadClientScreenSection
            lead={lead}
            updateField={updateLeadField}
            updateCallReview={updateCallReview}
          />
          {isBuyerLead(lead) && (
            <AgentMatchingLeadMortgageScreenSection
              lead={lead}
              updateField={updateLeadField}
              updateCallReview={updateCallReview}
            />
          )}
          <Sections>
            <Section>
              {' '}
              <AgentMatchingLeadAgentScreenSection
                lead={lead}
                updateCallReview={updateCallReview}
              />
            </Section>
            {isSellerLead(lead) && !isFlexUi && (
              <Section>
                {' '}
                <AgentMatchingLeadInvestorScreenSection
                  lead={lead}
                  updateCallReview={updateCallReview}
                  investorIntroFieldsActive={salesAppInvestorAutoIntroFields?.enabled}
                />
              </Section>
            )}
          </Sections>
          {isSellerLead(lead) && (
            <AgentMatchingLeadBBYSScreenSection
              lead={lead}
              updateCallReview={updateCallReview}
              updateField={updateLeadField}
            />
          )}
          {isSellerLead(lead) && (
            <AgentMatchingLeadLenderScreenSection
              lead={lead}
              updateCallReview={updateCallReview}
              updateField={updateLeadField}
            />
          )}
        </>
      ) : (
        <>
          <Sections title={`${d(lead.userType)}: ${d(lead.stageAndFurthestStage)}`}>
            <Section>
              <FormFieldSet>
                <FormField title="Created At" inline>
                  {formatDate(lead.createdAt)}
                </FormField>
                <FormField title="Last Call Date" inline>
                  {formatDate(lead.lastCallDate)}
                </FormField>
                <FormField title="Price" inline>
                  {formatPrice(lead.price)}
                </FormField>
                <FormField title="Timeline" inline>
                  {lead.humanTimeline}
                </FormField>
                <FormField title="Agent Comm Pref" inline>
                  {lead.callReview.agentCommunicationPreference}
                </FormField>
                <FormField title="Best Contact" inline>
                  {lead.callReview.bestContact}
                </FormField>
                <FormField title="Need To Meet Next Day" inline>
                  {lead.callReview.needToMeetInNextDay}
                </FormField>
              </FormFieldSet>
            </Section>
            <Section>
              {isActive(lead.id) ? (
                <FormField title="Queue Time" inline>
                  <Duration since={getSinceDate(lead.id)} live />
                </FormField>
              ) : null}
              {isSellerLead(lead) && (
                <FormField title={needsListingReviewField.name} inline>
                  <InlineEditSelect
                    onBlur={value => updateLeadField({ needsListingReview: value })}
                    options={needsListingReviewField.options}
                    value={stringifyValue(lead.needsListingReview)}
                  />
                </FormField>
              )}
              {salesAppBuyersSellersPendingActions.enabled && (
                <S.FormField title={pendingActionsField.name} inline>
                  {lead?.pendingActions.length ? (
                    <>
                      <ResolvePendingReviewButtonForm
                        leadId={lead.id}
                        leadType={lead?.userType}
                        pendingReviews={lead.pendingActions}
                      />
                    </>
                  ) : (
                    <Text color="decorative5" hasEllipsis size="xs">
                      {DEFAULT_STRING}
                    </Text>
                  )}
                </S.FormField>
              )}

              <FormField title="Intro Sent at" inline>
                {formatDate(lead.introSentAt)}
              </FormField>
              <FormField title="Source" inline>
                {`${titleize(lead.source)} (${titleize(lead.sourcePageType)})`}
              </FormField>
              <FormField title="Relationship" inline>
                {lead.callReview.relationship}
              </FormField>
              <FormField title="Auto-Intro Count" inline>
                {lead.callReview.autoIntroCount}
              </FormField>
              <FormField title="High Touch" inline>
                {lead.callReview.highTouch}
              </FormField>
              <FormField title="Brokerages To Exclude" inline>
                {lead.callReview.brokeragesToExclude.join(', ')}
              </FormField>
            </Section>
          </Sections>
          <Sections title="Property Details">
            <Section>
              <FormField title="Full Address" inline>
                <Block title={lead.fullAddress} />
              </FormField>
              <FormField title="Property Type" inline>
                {d(lead.propertyType)}
              </FormField>
              <FormField title="Foreclosure" inline>
                {lead.callReview.foreclosure}
              </FormField>
              {isBuyerLead(lead) ? (
                <FormField title="Home Use Type" inline>
                  {lead.callReview.homeUseType}
                </FormField>
              ) : null}
              <FormField title="External Links" inline>
                <List>
                  <ExternalLink
                    href={`https://www.google.com/search?q=${toSearchParam(lead.fullAddress)}`}
                  >
                    Google Address
                  </ExternalLink>
                  <ExternalLink
                    href={`http://www.zillow.com/homes/${toSearchParam(lead.fullAddress)}_rb`}
                  >
                    Zillow Address
                  </ExternalLink>
                </List>
              </FormField>
              {isSellerLead(lead) && (
                <>
                  <FormField title="Listing Status" inline>
                    <Block title={dataOrDash(lead.externalListing?.status)} />
                  </FormField>
                  <FormField title="Listing Status Updated Date" inline>
                    <Block title={formatDate(lead.externalListing?.updatedAt)} />
                  </FormField>
                </>
              )}
            </Section>
            <Section>
              {lead.latitude && lead.longitude ? (
                <MapBox center={lead.toPoint()} markers={[lead.toMarker()]} />
              ) : (
                <FormField title="Map" inline>
                  Latitude and Longitude are missing
                </FormField>
              )}
            </Section>
          </Sections>
          {isBuyerLead(lead) && (
            <Sections>
              {lead.partnerLender && (
                <Section title="Lender Details">
                  <FormField title="Lender Name" inline>
                    {lead.partnerLender.lenderName}
                  </FormField>
                  <FormField title="Lender ID" inline>
                    {lead.partnerLender.externalLenderId}
                  </FormField>
                </Section>
              )}
              {lead.partnerLoanOfficer && (
                <Section title="Loan Officer Details">
                  <FormField title="Loan Officer" inline>
                    {lead.partnerLoanOfficer.loanOfficerName}
                  </FormField>
                  <FormField title="Loan Officer Phone" inline>
                    {formatPhone(lead.partnerLoanOfficer.loanOfficerPhone)}
                  </FormField>
                  <FormField title="Loan Officer ID" inline>
                    {lead.partnerLoanOfficer.loanOfficerId}
                  </FormField>
                </Section>
              )}
            </Sections>
          )}
          <Sections>
            <Section title="Sales Notes" actions={newSalesNoteFormButton}>
              <ViewMore height={120}>{lead.salesNotes}</ViewMore>
            </Section>
            <Section title="Notes to Agents" actions={editAgentNoteFormButton}>
              <ViewMore height={120}>{lead.notesToAgents}</ViewMore>
            </Section>
          </Sections>
        </>
      )}

      <ParamsProvider>
        <LeadAgents lead={lead} />
      </ParamsProvider>
    </Contents>
  )
}
