// @ts-strict

import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useToastContext } from '@foundation/components'
import { Option } from 'classes'
import { InlineEditMultiSelect, TableCell as Td, TableRow as Tr } from 'components'
import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard'
import { AgentPrograms } from 'content/Agent'
import { AgentLinkWithMenuCard } from 'content/Agent/AgentDetailsMenuCard'
import { TeamMemberActionMenu } from 'content/Agent/AgentTeam'
import { useAgentTeamContext, useCurrentUserContext } from 'contexts'
import { useUserController } from 'controllers'
import { useFeatureFlags } from 'hooks'
import { Agent, AgentTeamMembership } from 'models'
import { d, formatBoolean } from 'utils'

type Props = {
  teamMember: AgentTeamMembership
}

const leadTypeOptions = [
  { name: 'Buyers', value: 'prefersBuyer' },
  { name: 'Sellers', value: 'prefersSeller' }
].map(option => new Option(option))

const leadTypeValues = (agent?: Agent): string[] =>
  (agent &&
    [
      agent?.referralPreferences?.prefersBuyer ? 'prefersBuyer' : null,
      agent?.referralPreferences?.prefersSeller ? 'prefersSeller' : null
    ].filter((v): v is string => v !== null)) ??
  []

export const AgentTeamMembershipsTableRow = ({ teamMember }: Props) => {
  const { removeTeamMember, updateReferralPreferences } = useAgentTeamContext()
  const { salesAppAgentDetailsComponent } = useFeatureFlags(['sales-app-agent-details-component'])
  const { canImpersonateUser } = useCurrentUserContext()
  const { userImpersonate } = useUserController(teamMember.user!.id)
  const { agentTeamQuery } = useAgentTeamContext()
  const toast = useToastContext()

  const agentTeam = agentTeamQuery.data

  const onImpersonate = () => {
    if (!canImpersonateUser) {
      toast({ title: 'Unable to impersonate agent', status: 'error' })
    }

    userImpersonate.mutate(undefined, {
      onError: () => toast({ title: 'Unable to impersonate agent', status: 'error' })
    })
  }

  const handleLeadTypeChange = (values: string[]) => {
    updateReferralPreferences.mutate(
      {
        id: teamMember.id,
        prefersBuyer: values.includes('prefersBuyer'),
        prefersSeller: values.includes('prefersSeller')
      },
      {
        onError: () => toast({ title: 'Unable to udpate lead type', status: 'error' })
      }
    )
  }

  const handleRemoveAgent = () => {
    removeTeamMember.mutate(teamMember.id, {
      onError: () => toast({ title: 'Unable to remove team member', status: 'error' })
    })
  }

  const handleToggleLeadRouting = () => {
    const setLeadRoutingActive = !teamMember?.user?.agent?.referralPreferences?.active

    if (setLeadRoutingActive && !agentTeam?.hasLeadRoutingSeatsAvailable()) {
      toast({
        title: 'Max numbers of agents for Lead Routing exceeded. Remove one to add another.',
        status: 'error'
      })
      return
    }

    const payload = {
      id: teamMember.id,
      active: setLeadRoutingActive,
      prefersBuyer: setLeadRoutingActive,
      prefersSeller: setLeadRoutingActive
    }

    updateReferralPreferences.mutate(payload, {
      onError: () =>
        toast({
          title: `Unable to ${
            setLeadRoutingActive ? 'add' : 'remove'
          } team member from lead routing`,
          status: 'error'
        })
    })
  }

  const handleSetFallbackAgent = () => {
    updateReferralPreferences.mutate(
      { id: teamMember.id, active: true, isFallbackAgent: true },
      {
        onError: () => toast({ title: 'Unable to set fallback agent', status: 'error' })
      }
    )
  }

  const handleSetLeadRoutingWarmTransferOptIn = () => {
    const setWarmTransferOptIn =
      !teamMember?.user?.agent?.referralPreferences?.leadRoutingWarmTransferOptIn

    if (setWarmTransferOptIn && !agentTeam?.hasWarmTransferSeatsAvailable()) {
      toast({
        title: 'Max numbers of agents for Warm Transfer exceeded. Remove one to add another.',
        status: 'error'
      })
      return
    }

    const payload = {
      id: teamMember.id,
      leadRoutingWarmTransferOptIn: setWarmTransferOptIn
    }

    updateReferralPreferences.mutate(payload, {
      onError: () =>
        toast({
          title: `Unable to ${
            setWarmTransferOptIn ? 'add' : 'remove'
          } team member from warm trasnfer`,
          status: 'error'
        })
    })
  }

  const { user } = teamMember

  if (!user) {
    return null
  }

  const renderAgentLink = () => {
    return salesAppAgentDetailsComponent?.enabled ? (
      <AgentLinkWithMenuCard agent={user.agent!} />
    ) : (
      <Link to={`/agents/${user.agent!.id}`}>{`${user.firstName} ${user.lastName}`}</Link>
    )
  }

  return (
    <Tr key={user.id}>
      <Td>{user.agent ? renderAgentLink() : `${user.firstName} ${user.lastName}`}</Td>
      <TdEmail>
        {user.email} <CopyToClipboard textToCopy={user.email || ''} />
      </TdEmail>
      <Td>{d(teamMember.role)}</Td>
      <Td>{formatBoolean(user.agent?.referralPreferences?.active)}</Td>
      <Td>{formatBoolean(user.agent?.referralPreferences?.isFallbackAgent)}</Td>
      <Td>
        <InlineEditMultiSelect
          values={leadTypeValues(user.agent)}
          onBlur={values => handleLeadTypeChange(values)}
          options={leadTypeOptions}
        />
      </Td>

      <Td>{formatBoolean(user.agent?.referralPreferences?.leadRoutingWarmTransferOptIn)}</Td>
      <Td>
        <AgentPrograms agent={user.agent} />
      </Td>
      <Td>
        <TeamMemberActionMenu
          onRemoveTeamMember={handleRemoveAgent}
          onImpersonate={onImpersonate}
          onLeadRoutingToggle={handleToggleLeadRouting}
          onFallbackAgentToggle={handleSetFallbackAgent}
          onWarmTransferToggle={handleSetLeadRoutingWarmTransferOptIn}
          teamMember={teamMember}
        />
      </Td>
    </Tr>
  )
}

const TdEmail = styled(Td)`
  display: flex;
  align-items: center;
`
