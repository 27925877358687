// @ts-strict

import { useFileUploadsController } from 'controllers'
import { TASKS_CACHE_KEY } from 'controllers/cache'
import { useMutation, useQueryClient } from 'hooks'
import {
  alertError,
  postResendTaskEmail,
  putCompleteTask,
  putDismissTask,
  putReopenTask,
  putTask,
  TPostFileUploadPayload,
  TPutCompleteTaskVariables,
  TPutDismissTaskVariables
} from 'services'
import { useCurrentUserContext } from '../../contexts'
import { environment } from '../../environment'
import { Lead, Task } from '../../models'

const LAMBDA_URL = environment.pdfMergeUrl || ''
const LAMBDA_API_KEY = environment.pdfMergeApiKey || ''

export const useTaskController = (id: string, task?: Task) => {
  const queryCache = useQueryClient()
  const { currentUser } = useCurrentUserContext()
  const { uploadFile } = useFileUploadsController(
    { leadId: task?.attachable?.id || '' },
    {
      onSuccess: async keys => {
        try {
          const response = await fetch(LAMBDA_URL, {
            method: 'POST',
            body: JSON.stringify({
              s3Keys: keys,
              output_name: 'ir_contract'
            }),
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': LAMBDA_API_KEY
            }
          })

          if (!response.ok) {
            if (response.status === 400) {
              alertError(
                'Error - Documents are security protected. Please remove security or make an unprotected copy and try again.'
              )
            } else {
              console.log('response', response)
              alertError('Failed to process the uploaded file. Please try again.')
            }
          }
        } catch (error) {
          alertError('An error occurred while processing your file. Please try again.')
        }
      },
      fileMetadata: {
        fileType: 'pdf_merge',
        attachableId: task?.attachable?.id,
        documentType: 'bbys_ir_contract',
        source: 'sales',
        uploaded_by: currentUser?.id
      }
    }
  )

  const defaultCallback = {
    onSuccess: () => queryCache.invalidateQueries(TASKS_CACHE_KEY),
    onError: () => alertError()
  }

  const updateTask = useMutation(async (data: object) => {
    if ('files' in data) {
      const files: File[] = data.files as File[]
      delete data.files

      const payload: TPostFileUploadPayload = {
        files,
        lead: task?.attachable as Lead,
        name: 'Task File Upload',
        fileType: 'pdf_merge',
        subAttachableId: id,
        subAttachableType: 'Task'
      }

      uploadFile.mutate(payload)
    }

    return putTask(id, data)
  }, defaultCallback)

  const completeTask = useMutation(
    (payload: TPutCompleteTaskVariables['payload']) => putCompleteTask({ id, payload }),
    defaultCallback
  )

  const dismissTask = useMutation(
    (payload: TPutDismissTaskVariables['payload']) => putDismissTask({ id, payload }),
    defaultCallback
  )

  const reopenTask = useMutation(() => putReopenTask({ id }), defaultCallback)

  const resendTaskEmail = useMutation(postResendTaskEmail, defaultCallback)

  return {
    completeTask,
    resendTaskEmail,
    updateTask,
    dismissTask,
    reopenTask
  }
}
