import {
  FormField,
  InlineEditEmailInput,
  InlineEditPhoneInput,
  InlineEditTextInput,
  Section,
  Sections
} from 'components'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'
import { getBBYSLeadInputProps, getLeadInputProps } from './utils'

type Props = Pick<CCBBYSLeadDetailsProps, 'lead' | 'updateBBYSLead' | 'updateLead'>

export const AdditionalClientInformationSection = ({ lead, updateBBYSLead, updateLead }: Props) => {
  const bbysLead = lead.bbysProviderLead.bbysLead
  const { getProps } = getBBYSLeadInputProps(bbysLead, updateBBYSLead)
  const { getProps: getLeadProps } = getLeadInputProps(lead, updateLead)

  return (
    <Sections title="Additional Client">
      <Section>
        <FormField title="Name" inline>
          <InlineEditTextInput {...getLeadProps('partnerName')} />
        </FormField>
        <FormField title="Name on Title" inline>
          <InlineEditTextInput {...getProps('additionalClientNameOnTitle')} />
        </FormField>
      </Section>
      <Section>
        <FormField title="Email Address" inline>
          <InlineEditEmailInput {...getLeadProps('emailAlt')} />
        </FormField>

        <FormField title="Phone Number" inline>
          <InlineEditPhoneInput {...getLeadProps('phoneAlt')} />
        </FormField>
      </Section>
    </Sections>
  )
}
