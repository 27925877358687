// @ts-strict
import { Badge, Table, TableResults } from 'components'
import { TableHeaderCell as TH, TableHeaderRow as TR } from 'components'
import { TableCell as Td, TableRow as Tr } from 'components'
import { Agent, Investor, PayPerLeadBillingInfo } from 'models'
import { formatDate, formatDollars, formatDollarsAndCents } from 'utils'
import styles from './BillingCyclesActionMenu.module.scss'
import { BillingCyclesActionMenu } from './'

type BillingCyclesTableProps = {
  billingCycles: PayPerLeadBillingInfo[]
  provider: Agent | Investor
}

type BillingCyclesRowProps = {
  billingCycle: PayPerLeadBillingInfo
  provider: Agent | Investor
}

const statusBadge = (status?: string) => {
  let badgeStyle = styles.inactiveBadge

  switch (status) {
    case 'Active':
    case 'Paid':
      badgeStyle = styles.activeBadge
      break
    case 'Payment Processing':
    case 'Hold Pending':
      badgeStyle = styles.waitingBadge
      break
    default:
      badgeStyle = styles.inactiveBadge
      break
  }

  return <Badge className={badgeStyle}>{status}</Badge>
}

const displayAmount = (billingCycle: PayPerLeadBillingInfo) => {
  if (billingCycle.billingCycleEndDate) {
    return <b>{formatDollarsAndCents(billingCycle.displayAmount(), '$0.00')}</b>
  }
  return <>{formatDollarsAndCents(billingCycle.displayAmount(), '$0.00')}</>
}

export const BillingCyclesTable = ({ provider, billingCycles }: BillingCyclesTableProps) => {
  return (
    <Table data-testid="billing-cycles-table" wrapperClassName={styles.table}>
      <TR>
        <TH>Amount</TH>
        <TH size="lg">Status</TH>
        <TH>Start Date</TH>
        <TH>End Date</TH>
        <TH>Cadence</TH>
        <TH>Seller Cap</TH>
        {provider.modelName === 'Agent' && <TH>Buyer Cap</TH>}
        <TH>Actions</TH>
      </TR>
      <TableResults type="Pay-Per-Lead Billing Cycles" colSpan={2}>
        {billingCycles?.map((billingCycle: PayPerLeadBillingInfo, index: number) => (
          <BillingCyclesRow key={index} provider={provider} billingCycle={billingCycle} />
        ))}
      </TableResults>
    </Table>
  )
}

const BillingCyclesRow = ({ provider, billingCycle }: BillingCyclesRowProps) => {
  return (
    <Tr data-testid="billing-cycles-replace-me">
      <Td>{displayAmount(billingCycle)}</Td>
      <Td>{statusBadge(billingCycle.displayStatus)}</Td>
      <Td>{formatDate(billingCycle.billingCycleStartDate)}</Td>
      <Td>{formatDate(billingCycle.billingCycleEndDate)}</Td>
      <Td>{billingCycle.displayFrequency()}</Td>
      <Td>{formatDollars((billingCycle.monthlyCap || 0) / 100)}</Td>
      {provider.modelName === 'Agent' && (
        <Td>{formatDollars((billingCycle.buyerMonthlyCap || 0) / 100)}</Td>
      )}
      <Td>
        <BillingCyclesActionMenu provider={provider} billingCycle={billingCycle} />
      </Td>
    </Tr>
  )
}
