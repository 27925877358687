// @ts-strict

import { ButtonForm, ButtonV2, FormField, Section, Select, TextArea } from 'components'
import { useCurrentUserContext, useLeadContext } from 'contexts'
import { useFeatureFlags } from 'hooks'
import {
  investorLeadDispositionNotIntroducedOptions,
  investorLeadDispositionOptions
} from 'lookups'
import { InvestorLead } from 'models'
import { d } from 'utils'
import styles from './InvestorLeadDispositionButtonForm.module.scss'
import { useInvestorLeadDispositionButtonForm } from './useInvestorLeadDispositionButtonForm'

type TProps = {
  investorLead: InvestorLead
}

export const InvestorLeadDispositionButtonForm = ({ investorLead }: TProps) => {
  const { lead } = useLeadContext()
  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useInvestorLeadDispositionButtonForm(investorLead, lead)
  const { salesAppInvestorAutoIntroFields } = useFeatureFlags([
    'sales-app-investor-auto-intro-fields'
  ])

  const isIntroduced = !!investorLead.providerLead.introSentAt

  const stageOptions = isIntroduced
    ? investorLeadDispositionOptions
    : investorLeadDispositionNotIntroducedOptions

  const stage = investorLead.providerLead.stage || ''
  const furthestStage = investorLead.providerLead.furthestStage

  const stageLabel = ['failed', 'nurture'].includes(stage)
    ? `${d(stage)} (${d(furthestStage)})`
    : d(stage)

  const { isHandMatchingAdmin } = useCurrentUserContext()

  if (!isHandMatchingAdmin && salesAppInvestorAutoIntroFields.enabled) {
    return null
  }

  return (
    <ButtonForm
      title="Investor Lead Disposition"
      buttonText="Disposition"
      buttonComponent={buttonProps => (
        <ButtonV2 className={styles.dispositionButton} {...buttonProps} />
      )}
      {...formProps}
    >
      <Section>
        <FormField title="Investor" inline className={styles.info}>
          {investorLead.investor?.name}
        </FormField>
        <FormField title="Current Stage" inline className={styles.info}>
          {stageLabel}
        </FormField>
      </Section>
      <Section>
        <FormField title="Update Stage" required>
          <Select
            addBlank
            options={stageOptions}
            value={String(payload.disposition)}
            onChange={val => onChangeAttribute('disposition', val)}
          />
        </FormField>
        {isIntroduced && (
          <FormField title="Note" htmlFor="note">
            <TextArea
              id="note"
              value={payload.note}
              onChange={val => onChangeAttribute('note', val)}
            />
          </FormField>
        )}
      </Section>
    </ButtonForm>
  )
}
