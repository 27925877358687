// @ts-strict
import type { UseMutationResult } from 'react-query'
import { useButtonForm } from 'components'
import type { LeadGroupable } from 'models'

type PutLeadGroupablePayload = any

type LeadGroupableButtonFormPayload = PutLeadGroupablePayload

export const useLeadGroupableAsideButtonForm = (
  mutation: UseMutationResult<any, unknown, PutLeadGroupablePayload, unknown>,
  leadGroupable: LeadGroupable
) => {
  const transformPayload = (
    payload: LeadGroupableButtonFormPayload
  ): LeadGroupableButtonFormPayload => {
    return {
      name: payload.name,
      active: payload.active === 'true',
      priority: parseInt(payload.priority),
      slug: payload.slug
    }
  }

  const { fieldsProps, formProps, setters } = useButtonForm<LeadGroupableButtonFormPayload>({
    initialPayload: {
      name: leadGroupable.name,
      active: leadGroupable.active ? 'true' : 'false',
      priority: leadGroupable.priority?.toString(),
      slug: leadGroupable.slug
    },
    mutation,
    transformPayload
  })

  return { fieldsProps, formProps, setters }
}
