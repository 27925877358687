import {
  FormField,
  InlineEditBoolean,
  InlineEditDollarsAndCentsInput,
  InlineEditSelect,
  Section,
  Sections
} from 'components'
import { equityBoostStages, trueFalseOptions } from 'lookups'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'
import { getBBYSLeadInputProps } from './utils'

type Props = Pick<CCBBYSLeadDetailsProps, 'lead' | 'updateBBYSLead' | 'updateLead'>

export const EquityBoostInformationSection = ({ lead, updateBBYSLead, updateLead }: Props) => {
  const bbysLead = lead.bbysProviderLead.bbysLead
  const { getProps } = getBBYSLeadInputProps(bbysLead, updateBBYSLead)

  return (
    <Sections title="Equity Boost Information">
      <Section>
        <FormField title="Equity Boost" inline>
          <InlineEditBoolean options={trueFalseOptions} {...getProps('equityBoost')} />
        </FormField>
        <FormField title="Equity Boost Stage" inline>
          <InlineEditSelect options={equityBoostStages} {...getProps('equityBoostStage')} />
        </FormField>
        <FormField title="Equity Boost Target Amount" inline>
          <InlineEditDollarsAndCentsInput {...getProps('equityBoostTargetAmount')} readOnly />
        </FormField>
        <FormField title="Equity Boost Amount (approved)" inline>
          <InlineEditDollarsAndCentsInput {...getProps('equityBoostAmount')} />
        </FormField>
      </Section>
      <Section>
        <FormField title="Equity Boost Funded" inline>
          <InlineEditDollarsAndCentsInput {...getProps('equityBoostFunded')} />
        </FormField>
        <Section borderless title="Equity Boost Assets">
          <FormField title="Savings Accounts" inline>
            <InlineEditDollarsAndCentsInput {...getProps('equityBoostCheckingSavingsAccount')} />
          </FormField>
          <FormField title="IRA & Brokerage Accounts" inline>
            <InlineEditDollarsAndCentsInput {...getProps('equityBoostIraBrokerageAccount')} />
          </FormField>
          <FormField title="401k" inline>
            <InlineEditDollarsAndCentsInput {...getProps('equityBoost401k')} />
          </FormField>
        </Section>
      </Section>
    </Sections>
  )
}
