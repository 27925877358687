import { useEffect } from 'react'
import { Contents } from 'components'
import { useFeatureFlags, useLeadController, UseMutationResult } from 'hooks'
import { CCBBYSLead } from 'models'
import { Partner } from 'models/Partner'
import { BBYSLeadDetailsSection } from './CCBBYS'
import { AdditionalClientInformationSection } from './CCBBYS/AdditionalClientInformationSection'
import { ClientInformationSection } from './CCBBYS/ClientInformationSection'
import { DRAgentInformationSection } from './CCBBYS/DRAgentInformationSection'
import { DRCoListingAgentInformationSection } from './CCBBYS/DRCoListingAgentInformationSection'
import { DRPropertyInformationSection } from './CCBBYS/DRPropertyInformationSection'
import { DRPropertyListingOrSaleInformationSection } from './CCBBYS/DRPropertyListingOrSaleInformationSection'
import { EquityBoostInformationSection } from './CCBBYS/EquityBoostInformationSection'
import { EquityUnlockInformationSection } from './CCBBYS/EquityUnlockInformationSection'
import { IRPropertyDetailsSection } from './CCBBYS/IRPropertyDetailsSection'
import { LenderSalesConsultantSection } from './CCBBYS/LenderSalesConsultantSection'
import { LoanOfficerAssistantSection } from './CCBBYS/LoanOfficerAssistantSection'
import { LoanOfficerContactsSection } from './CCBBYS/LoanOfficerContactsSection'
import { LoanOfficerInformationSection } from './CCBBYS/LoanOfficerInformationSection'
import { TransactionCoordinatorSection } from './CCBBYS/TransactionCoordinatorSection'

export type CCBBYSLeadDetailsProps = {
  lead: CCBBYSLead
  partners: Partner[]
  runPropertyQualification: UseMutationResult<unknown, unknown, { id: string }>
  updateBBYSLead: UseMutationResult
  updateLead: (payload: Record<string, string>) => void
  updateLenderLead: UseMutationResult
  updateOrder: (payload: Record<string, string>) => void
}

export const CCBBYSLeadDetails = ({
  lead,
  partners,
  runPropertyQualification,
  updateLead,
  updateOrder,
  updateBBYSLead,
  updateLenderLead
}: CCBBYSLeadDetailsProps) => {
  const { refetch } = useLeadController(lead.id, 'bbys', true)

  const { equityBoostQuestionnaire } = useFeatureFlags([
    'field-data-automation',
    'bbys-inspectify-integration',
    'equity-boost-questionnaire'
  ])

  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <Contents>
      <BBYSLeadDetailsSection
        lead={lead}
        updateLead={updateLead}
        updateBBYSLead={updateBBYSLead}
        partners={partners}
      />
      <DRPropertyInformationSection
        lead={lead}
        updateBBYSLead={updateBBYSLead}
        updateLead={updateLead}
      />
      <DRPropertyListingOrSaleInformationSection
        lead={lead}
        updateBBYSLead={updateBBYSLead}
        updateLead={updateLead}
      />
      <IRPropertyDetailsSection
        lead={lead}
        updateBBYSLead={updateBBYSLead}
        updateLead={updateLead}
      />
      <EquityUnlockInformationSection
        lead={lead}
        updateBBYSLead={updateBBYSLead}
        updateLead={updateLead}
      />
      {equityBoostQuestionnaire.enabled && (
        <EquityBoostInformationSection
          lead={lead}
          updateBBYSLead={updateBBYSLead}
          updateLead={updateLead}
        />
      )}
      <ClientInformationSection
        lead={lead}
        updateBBYSLead={updateBBYSLead}
        updateLead={updateLead}
      />
      <AdditionalClientInformationSection
        lead={lead}
        updateBBYSLead={updateBBYSLead}
        updateLead={updateLead}
      />
      <LoanOfficerInformationSection
        lead={lead}
        updateBBYSLead={updateBBYSLead}
        updateLead={updateLead}
      />
      <LoanOfficerAssistantSection
        lead={lead}
        updateBBYSLead={updateBBYSLead}
        updateLead={updateLead}
      />
      <LoanOfficerContactsSection
        lead={lead}
        updateBBYSLead={updateBBYSLead}
        updateLead={updateLead}
      />
      <LenderSalesConsultantSection
        lead={lead}
        updateBBYSLead={updateBBYSLead}
        updateLead={updateLead}
      />
      <DRAgentInformationSection lead={lead} updateBBYSLead={updateBBYSLead} />
      <DRCoListingAgentInformationSection lead={lead} updateBBYSLead={updateBBYSLead} />
      <TransactionCoordinatorSection
        lead={lead}
        updateBBYSLead={updateBBYSLead}
        updateLead={updateLead}
      />
    </Contents>
  )
}
