// @ts-strict
import { Option } from 'classes'
import { ButtonForm, FormField, NegativeIntegerInput, Section, Select, TextInput } from 'components'
import { useCurrentUserContext, useLeadGroupableContext } from 'contexts'
import { LeadGroupable } from 'models'
import { useLeadGroupableAsideButtonForm } from './useLeadGroupableAsideButtonForm'

type Props = {
  leadGroupable: LeadGroupable
}

const booleanOptions = [
  { name: 'Yes', value: 'true' },
  { name: 'No', value: 'false' }
].map(option => new Option(option))

export const LeadGroupableAsideButtonForm = ({ leadGroupable }: Props) => {
  const { updateLeadGroupable } = useLeadGroupableContext()
  const { isLeadCreationAdmin } = useCurrentUserContext()

  const {
    fieldsProps: { onChangeAttribute, payload },
    formProps
  } = useLeadGroupableAsideButtonForm(updateLeadGroupable, leadGroupable)

  if (!isLeadCreationAdmin) {
    return null
  }

  return (
    <ButtonForm buttonText="Edit" title="Update lead groupable" {...formProps}>
      <Section>
        <FormField title="Name" required>
          <TextInput
            onChange={val => onChangeAttribute('name', val)}
            name="name"
            value={payload.name}
            data-testid="name-field"
          />
        </FormField>
        <FormField title="Active" required>
          <Select
            value={payload.active === 'true' ? 'true' : 'false'}
            onChange={val => onChangeAttribute('active', val)}
            options={booleanOptions}
          />
        </FormField>
        <FormField title="Priority" required>
          <NegativeIntegerInput
            value={payload.priority.toString()}
            onChange={val => onChangeAttribute('priority', val)}
          />
        </FormField>
        <FormField title="Slug" required>
          <TextInput
            disabled={!!leadGroupable.slug}
            onChange={val => onChangeAttribute('slug', val)}
            name="slug"
            value={payload.slug}
            data-testid="slug-field"
          />
        </FormField>
      </Section>
    </ButtonForm>
  )
}
