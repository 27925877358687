// @ts-strict

import { useState } from 'react'
import { ButtonForm, useButtonForm } from 'components'
import { EditIconButton } from 'components/Button/IconButton'
import { useTaskController } from 'controllers'
import { useFeatureFlags } from 'hooks'
import { Task } from 'models'
import { TaskFormFields } from '../TaskFormFields'
import { DocumentsListProvider, useDocumentsList } from '../contexts/documentsList'
import { formValidations, RawDocument, transformPayload, TTaskFormPayload } from '../utils'

type TProps = { onSuccessCallback?: Function; task: Task }

const TaskEditButtonFormV1 = ({ task }: TProps) => {
  const { updateTask } = useTaskController(task.id, task)

  const initialPayload: TTaskFormPayload = {
    assigneeId: task.assignee?.id,
    assigneeName: task.assignee?.name,
    dueAt: task.dueAt,
    note: task.note,
    taskCategoryId: task.taskCategory?.id,
    title: task.title,
    requiredForLeadStage: task?.requiredForLeadStage,
    subAttachableId: task?.subAttachable?.id
  }

  const { fieldsProps, formProps } = useButtonForm({
    initialPayload,
    formValidations,
    mutation: updateTask,
    transformPayload: payload =>
      transformPayload({
        attachable: task.attachable,
        payload
      })
  })

  return (
    <ButtonForm title="Edit Task" buttonComponent={EditIconButton} {...formProps}>
      <TaskFormFields {...fieldsProps} attachable={task.attachable} />
    </ButtonForm>
  )
}

const TaskEditButtonFormV2 = ({ task }: TProps) => {
  const isDocumentUploadTask = task.templateSlug === 'bbys_document_upload'
  const isSendWireConfirmation = task.templateSlug === 'bbys_send_wire_confirmation'
  const isIrContractUploadTask = task.templateSlug === 'bbys_upload_ir_contract'
  const isIrContractReviewTask = task.templateSlug === 'bbys_review_ir_contract'
  const { wireConfirmationTaskFastFollows } = useFeatureFlags([
    'wire-confirmation-task-fast-follows'
  ])

  // Set send notification ON by default for wire confirmation tasks
  // https://docs.google.com/document/d/1QNOVtsKB1zr1kIktWsTKjGAUG8QLr765VJROx9SwoP8/edit
  const [sendNotification, setSendNotification] = useState(
    isSendWireConfirmation || !!task?.metadata.sendNotification
  )

  const { updateTask } = useTaskController(task.id, task)
  const documentsList = useDocumentsList({ initialDocuments: task.metadata?.documents })

  const initialPayload: TTaskFormPayload = {
    assigneeId: task.assignee?.id,
    assigneeName: task.assignee?.name,
    dueAt: task.dueAt,
    note: task.note,
    taskCategoryId: task.taskCategory?.id,
    title: task.title,
    requiredForLeadStage: task?.requiredForLeadStage,
    subAttachableId: task?.subAttachable?.id,
    metadata: task?.metadata
  }

  const { fieldsProps, formProps } = useButtonForm({
    initialPayload,
    formValidations,
    mutation: updateTask,
    transformPayload: payload =>
      transformPayload({
        attachable: task.attachable,
        payload,
        documents: documentsList.documents.map(doc => {
          const rawDoc = {
            title: doc.title,
            enabled: doc.enabled
          } as RawDocument

          if (doc.notes !== '') {
            rawDoc.notes = doc.notes
          }

          return rawDoc
        }),
        sendNotification
      })
  })

  const {
    payload: { metadata }
  } = fieldsProps
  const isWireConfirmationV2FormValid =
    metadata?.imadNumber &&
    metadata?.wireAmount &&
    metadata?.borrower?.firstName &&
    metadata?.borrower?.lastName
  const isWireConfirmationFormValid = wireConfirmationTaskFastFollows.enabled
    ? isWireConfirmationV2FormValid && metadata?.warehouseFacility
    : isWireConfirmationV2FormValid && metadata?.confirmationNumber
  const isFormValid = isSendWireConfirmation ? !!isWireConfirmationFormValid : formProps.isFormValid

  return (
    <DocumentsListProvider {...documentsList}>
      <ButtonForm
        {...formProps}
        title="Edit Task"
        buttonComponent={EditIconButton}
        isFormValid={isFormValid}
      >
        <TaskFormFields
          {...fieldsProps}
          attachable={task.attachable}
          isDocumentUploadTask={isDocumentUploadTask}
          isSendWireConfirmation={isSendWireConfirmation}
          isIrContractUploadTask={isIrContractUploadTask}
          isIrContractReviewTask={isIrContractReviewTask}
          sendNotification={sendNotification}
          setSendNotification={setSendNotification}
        />
      </ButtonForm>
    </DocumentsListProvider>
  )
}

export const TaskEditButtonForm = ({ task }: TProps) => {
  const { equityBbysDocCenterV2 } = useFeatureFlags(['equity-bbys-doc-center-v2'])

  if (equityBbysDocCenterV2.enabled) {
    return <TaskEditButtonFormV2 task={task} />
  }
  return <TaskEditButtonFormV1 task={task} />
}
