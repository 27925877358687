import {
  FormField,
  InlineEditAddressUnit,
  InlineEditDateInput,
  InlineEditDollarsAndCentsInput,
  InlineEditEmailInput,
  InlineEditPhoneInput,
  InlineEditSelect,
  InlineEditTextInput,
  Section,
  Sections
} from 'components'
import { incomingResidenceMortageTypeOptions, yesOrNoOptions } from 'lookups'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'
import { getBBYSLeadInputProps } from './utils'

type Props = Pick<CCBBYSLeadDetailsProps, 'lead' | 'updateBBYSLead' | 'updateLead'>

export const IRPropertyDetailsSection = ({ lead, updateBBYSLead, updateLead }: Props) => {
  const bbysLead = lead.bbysProviderLead.bbysLead
  const { getProps } = getBBYSLeadInputProps(bbysLead, updateBBYSLead)

  return (
    <Sections title="Incoming Residence">
      <Section>
        <FormField title="Property Address" inline>
          <InlineEditAddressUnit
            onBlur={val =>
              updateBBYSLead.mutate({
                id: bbysLead.id,
                payload: {
                  incoming_property_full_address: val || ''
                }
              })
            }
            value={bbysLead.incomingPropertyFullAddress}
          />
        </FormField>
        <FormField title="Under contract at intake" inline>
          <InlineEditSelect
            {...getProps('incomingResidenceUnderContractAtIntake')}
            options={yesOrNoOptions}
            addBlank
          />
        </FormField>
        <FormField title="Purchase price" inline>
          <InlineEditDollarsAndCentsInput {...getProps('incomingResidencePurchasePrice')} />
        </FormField>
        <FormField title="Financing type" inline>
          <InlineEditSelect
            {...getProps('incomingResidenceMortgageType')}
            options={incomingResidenceMortageTypeOptions}
            addBlank
          />
        </FormField>
        <FormField title="Loan contingency date" inline>
          <InlineEditDateInput {...getProps('incomingResidenceLoanContingencyEndDate')} />
        </FormField>
        <FormField title="Loan amount" inline>
          <InlineEditDollarsAndCentsInput {...getProps('incomingResidenceLoanAmount')} />
        </FormField>
        <FormField title="Down payment" inline>
          <InlineEditDollarsAndCentsInput {...getProps('incomingResidenceDownPayment')} />
        </FormField>
        <FormField title="Seller in possesion" inline>
          <InlineEditTextInput {...getProps('incomingResidenceSellerInPossesion')} />
        </FormField>
        <FormField title="Fully executed" inline>
          <InlineEditSelect
            {...getProps('incomingResidenceFullyExecutedContract')}
            options={yesOrNoOptions}
            addBlank
          />
        </FormField>
        <FormField title="Counter offers" inline>
          <InlineEditSelect
            {...getProps('incomingResidenceCounterOfferPresent')}
            options={yesOrNoOptions}
            addBlank
          />
        </FormField>
      </Section>
      <Section>
        <FormField title="Title company" inline>
          <InlineEditTextInput {...getProps('incomingResidenceTitleCompany')} />
        </FormField>
        <FormField title="Title company email address" inline>
          <InlineEditEmailInput {...getProps('incomingResidenceTitleCompanyEmail')} />
        </FormField>
        <FormField title="Title company phone number" inline>
          <InlineEditPhoneInput {...getProps('incomingResidenceTitleCompanyPhone')} />
        </FormField>
        <FormField title="Client in contract date" inline>
          <InlineEditDateInput {...getProps('departingResidenceClientInContractDate')} />
        </FormField>
      </Section>
    </Sections>
  )
}
