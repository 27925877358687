import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useToastContext } from '@foundation/components'
import theme from '@foundation/themes'
import { Icon } from 'components/Icon'

type CopyToClipboardProps = {
  style?: React.CSSProperties
  textToCopy: string
}

const CopyToClipboard = ({ textToCopy, style }: CopyToClipboardProps) => {
  const [isCopied, setIsCopied] = useState(false)

  const toast = useToastContext()

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isCopied) {
        setIsCopied(false)
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [isCopied])

  const handleOnCopy = () => {
    setIsCopied(true)

    toast({
      title: 'Copied to clipboard',
      status: 'success'
    })

    navigator.clipboard.writeText(textToCopy)
  }

  if (!textToCopy) {
    return null
  }

  return (
    <IconWrapper title="Copy to clipboard" onClick={handleOnCopy} isCopied={isCopied} style={style}>
      <CopyIcon isCopied={isCopied} />
      <CheckIcon isCopied={isCopied} />
    </IconWrapper>
  )
}

const IconWrapper = styled.div<{ isCopied: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  margin-left: 4px;
  cursor: pointer;
  width: 36px;
  height: 36px;

  &:hover {
    background-color: ${theme.colors.neutral3};
  }
`

const CheckIcon = styled(Icon.Check)<{ isCopied?: boolean }>`
  color: #38bf8f;
  width: ${({ isCopied }) => (isCopied ? '24px' : 0)};
  height: 24px;

  padding-left: ${({ isCopied }) => (isCopied ? '4px' : 0)};
  padding-top: ${({ isCopied }) => (isCopied ? '2px' : 0)};

  transition: all 300ms ease-in-out;
`

const CopyIcon = styled(Icon.Copy)<{ isCopied?: boolean }>`
  width: ${({ isCopied }) => (isCopied ? 0 : '28px')};
  height: 28px;

  padding-left: ${({ isCopied }) => (isCopied ? 0 : '4px')};
  padding-top: ${({ isCopied }) => (isCopied ? 0 : '2px')};

  transition: all 300ms ease-in-out;
`

export default CopyToClipboard
